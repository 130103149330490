/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import {
  hideAllNotifications,
  showNotification,
  SEVERITY,
} from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form, Field } from 'formik';
import { Container } from '../components';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import Textarea from 'react-textarea-autosize';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import DropzoneField from '../components/DropzoneField';
import { yupMaxEmailFileSizeText } from '../utils';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import * as analytics from '../utils/analytics';

const formDisturbanceOnlineStoreSchema = translate =>
  yup.object().shape({
    name: yup
      .string()
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    email: yup
      .string()
      .email(translate('formDisturbanceOnlineStore.message.invalidEmail'))
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    purchaseDate: yup
      .string()
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    route: yup
      .string()
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    travelDate: yup
      .string()
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    departureTime: yup
      .string()
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    paymentMethod: yup
      .string()
      .oneOf(
        [
          translate('formDisturbanceOnlineStore.paymentMethods.mobilePay'),
          translate('formDisturbanceOnlineStore.paymentMethods.onlineBank'),
          translate('formDisturbanceOnlineStore.paymentMethods.mastercard'),
          translate('formDisturbanceOnlineStore.paymentMethods.visa'),
        ],
        translate('formDisturbanceOnlineStore.message.invalidPaymentMethod')
      )
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    onlineBank: yup.string(),
    paymentReference: yup
      .string()
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    chargedAmount: yup
      .string()
      .required(translate('formDisturbanceOnlineStore.message.requiredField')),
    accountNumber: yup.string(),
    additionalInformation: yup.string(),
    attachment: yup
      .array()
      .of(yup.mixed())
      .max(2, translate('feedback.message.tooManyFiles', { files: 2 }))
      .ensure(),
    /*.test(
        'tooBig',
        translate('feedback.message.sizeLimit', { size: '10MB' }),
        yupMaxEmailFileSizeText
      )*/ recaptcha: yup
      .string()
      .required(),
  });

  const maxSize = 6000; // about 5.9 MB

const DisturbanceOnlineStoreForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  errors,
  nocaptcha,
}) => {
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <FormField
        name="name"
        label={translate('formDisturbanceOnlineStore.name')}
      />
      <FormField
        name="email"
        label={translate('formDisturbanceOnlineStore.email')}
      />
      <FormField
        name="purchaseDate"
        label={translate('formDisturbanceOnlineStore.purchaseDate')}
      />
      <FormField
        name="route"
        label={translate('formDisturbanceOnlineStore.route')}
      />
      <FormField
        name="travelDate"
        label={translate('formDisturbanceOnlineStore.travelDate')}
      />
      <FormField
        name="departureTime"
        label={translate('formDisturbanceOnlineStore.departureTime')}
      />
      <RadioFieldGroup
        id="paymentMethod"
        error={errors.paymentMethod}
        label={translate('formDisturbanceOnlineStore.paymentMethod')}
      >
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('formDisturbanceOnlineStore.paymentMethods.mobilePay')}
          label={translate(
            'formDisturbanceOnlineStore.paymentMethods.mobilePay'
          )}
        />
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('formDisturbanceOnlineStore.paymentMethods.onlineBank')}
          label={translate(
            'formDisturbanceOnlineStore.paymentMethods.onlineBank'
          )}
        />
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('formDisturbanceOnlineStore.paymentMethods.mastercard')}
          label={translate(
            'formDisturbanceOnlineStore.paymentMethods.mastercard'
          )}
        />
        <Field
          component={RadioField}
          name="paymentMethod"
          id={translate('formDisturbanceOnlineStore.paymentMethods.visa')}
          label={translate('formDisturbanceOnlineStore.paymentMethods.visa')}
        />
      </RadioFieldGroup>
      <FormField
        name="onlineBank"
        label={translate('formDisturbanceOnlineStore.onlineBank')}
      />
      <FormField
        name="paymentReference"
        label={translate('formDisturbanceOnlineStore.paymentReference')}
      />
      <FormField
        name="chargedAmount"
        label={translate('formDisturbanceOnlineStore.chargedAmount')}
      />
      <FormField
        name="accountNumber"
        label={translate('formDisturbanceOnlineStore.accountNumber')}
      />
      <FormField
        name="additionalInformation"
        as={Textarea}
        label={translate('formDisturbanceOnlineStore.additionalInformation')}
      />
      <DropzoneField
        name="attachment"
        label={translate('formDisturbanceOnlineStore.attachment')}
        newLabel={translate('formDisturbanceOnlineStore.attachmentSize', { maxSize: maxSize / 1000})}
        placeholder={translate('formDisturbanceOnlineStore.dropzoneText')}
        clearButtonText={translate('formDisturbanceOnlineStore.clear')}
        zoneActiveText={translate(
          'formDisturbanceOnlineStore.dropzoneActiveText'
        )}
      />
      <RecaptchaButton
        buttonText="formDisturbanceOnlineStore.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
      />
    </Form>
  );
};

const DisturbanceOnlineStoreFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      name: vo.name || '',
      email: vo.email || '',
      purchaseDate: vo.purchaseDate || '',
      route: vo.route || '',
      travelDate: vo.travelDate || '',
      departureTime: vo.departureTime || '',
      paymentMethod: vo.paymentMethod || '',
      onlineBank: vo.onlineBank || '',
      paymentReference: vo.paymentReference || '',
      chargedAmount: vo.chargedAmount || '',
      accountNumber: vo.accountNumber || '',
      additionalInformation: vo.additionalInformation || '',
      attachment: vo.attachment || [],
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return formDisturbanceOnlineStoreSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'DisturbanceOnlineStoreInputForm',
})(DisturbanceOnlineStoreForm);

const FormDisturbanceOnlineStorePage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());
      try {
        await api.sendFormDisturbanceOnlineStore({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: {
              message: translate('formDisturbanceOnlineStore.message.success'),
            },
          })
        );
        //dispatch(showNotification('formDisturbanceOnlineStore.message.success', SEVERITY.INFO));
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(
          showNotification(
            'formDisturbanceOnlineStore.message.failed',
            SEVERITY.ERROR
          )
        );
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout
      title={translate('formDisturbanceOnlineStore.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('formDisturbanceOnlineStore.title')}</Styled.h1>
        <DisturbanceOnlineStoreFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default FormDisturbanceOnlineStorePage;
